// Main pages for the main nav.
import * as React from 'react';
import { Link } from "react-router-dom";

class NavigationMainPages extends React.Component {

    render() {
        return (
            <ul>{true === false ? (<>if logged in, show some options</>) : (<Link to="/explore">Explore</Link>)}
            </ul>
        );
    }
}

export default NavigationMainPages;
