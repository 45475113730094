// Basic component for adding a new number.
import * as React from 'react';
import { Link } from "react-router-dom";

class ButtonCreateNumber extends React.Component {

    render() {
        return (
            <Link className="button create-number large solid dark" to="/number/create">
                <span>Add a new number</span>
            </Link>
        );
    }
}

export default ButtonCreateNumber;
