// Basic header component.
import * as React from 'react';
import { Link } from "react-router-dom";

// Import components.
import NavigationMain from "./Navigation/NavigationMain";

class Header extends React.Component {

    render() {
        return (
            <div id="header">
                <div className="left"><Link to="/"><span>Noomero</span></Link></div>
                <div className="right"><NavigationMain /></div>
            </div>
        );
    }
}

export default Header;
