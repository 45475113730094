// Main pages for the main nav.
import * as React from 'react';
// import { Link } from "react-router-dom";

class NavigationUserSettings extends React.Component {

    render() {
        return (
            <div>
                {true === false ? (<div>Logged in, show user settings</div>) : (<div>Get started</div>)}
            </div>
        );
    }
}

export default NavigationUserSettings;
