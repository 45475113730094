// Generic card component.

function Card(props) {

    let cardStyles = {
        backgroundColor: undefined !== props.backgroundColor ? props.backgroundColor : "#eeeeee",
        color: undefined !== props.textColor ? props.textColor : "inherit"
    };
    return (
        <div style={cardStyles} className="card">
            {undefined !== props.heading ? (<div className="card-heading">{props.heading}</div>) : null}
            {undefined !== props.text ? (<div className="card-text">{props.text}</div>) : null}
            {props.children}
        </div>
    );
}

export default Card;