// Page for showing more info about the app.
import React from "react";

class PageExplore extends React.Component {
    render() {
        return (
            <><h1>Explore</h1><p>More info about Noomero.</p></>
        )
    }
}

export default PageExplore;