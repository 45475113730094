// Form for saving a new number.

import React, { useState } from "react";
import FormField from "./Field";
import Button from "../Button";

// Get out API URL.
const APIUrl = process.env.REACT_APP_NOOMERO_API_URL;

function FormNumberCreate() {

    const [inputValue, setInputValue] = useState("");
    const [inputFieldMessage, setInputFieldMessage] = useState("");
    const [numberSaved, setNumberSaved] = useState(false);

    function handleInputChange(event) {
        setInputValue(event.target.value);
    }

    async function handleSubmit(event) {
        event.preventDefault();
        // console.log("Form submitted", `Input value is ${inputValue}`);

        // Remove any commas from the input.
        const inputValueCommasRemoved = inputValue.replace(/,/g, "");
        // Remove extra zeros from the start of the number.
        const inputValueFinal = inputValueCommasRemoved.replace(/^0+([0-9]+)/, "$1");
        // We could do this in future to further clean up the number:
        // const strippedNum = num.toString().replace(/^(-?)0+(?=\d)/, "$1");
        // return Number.parseFloat(strippedNum);
        // Pattern for checking valid numbers.
        const regex = /^[-+]?\d*\.?\d+$/;
        const isOKtoSubmit = regex.test(inputValueFinal);
        // console.log(`The input value is ${inputValueFinal}`);
        if (false === isOKtoSubmit) {
            setInputFieldMessage('That\'s not a valid number.');
            return;
        }
        setInputFieldMessage('');

        // @todo Change this later to user the current logged in user's ID.
        // We might want to save other stuff here too, including the location,
        // dateOfNumber and tags.
        const formData = {
            value: inputValueFinal,
            userId: 1
        };

        // This is the more basic API method.
        console.log('Checking the value of APIUrl', `${APIUrl}numbers/create-number`, APIUrl);
        try {
            const response = await fetch(`${APIUrl}numbers/create-number`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(formData),
            });

            const data = await response.json();
            // We have a response from the API, but does it mean the number is actually saved?
            if (false === data.hasOwnProperty('id')) {
                // Probably did not save a new number.
            }
            console.log('Data from response:', data);
            // If not saved properly...
            // If saved properly...
            setNumberSaved(true);
        } catch (error) {
            // Show an error to the user.
        }

    }

    const NumberInputProps = {
        label: "Number",
        fieldID: "save-number-field",
        inputPlaceholder: "123",
        inputName: "number",
        inputID: "save-number-input",
        fieldVariant: "large",
        inputValue: inputValue,
        onChange: handleInputChange
    };
    const ButtonProps = {
        buttonWidth: 'full-width',
        buttonSize: 'large',
        buttonTone: 'dark',
        buttonVariant: 'solid',
        type: 'submit'
    };

    if (numberSaved) {
        return <div>
            <p>Your number was saved!</p>
            <p><a href="/number/create" onClick={(event) => {
                setNumberSaved(false);
                setInputValue("");
                event.preventDefault();
            }}>Add another number</a></p>
        </div>;
    }

    return (
        <form onSubmit={handleSubmit}>
            <div id="number-input-message">{inputFieldMessage}</div>
            <FormField {...NumberInputProps} />
            <div className="submit-button-container">
                <Button {...ButtonProps}>Save Number</Button>
            </div>
        </form>
    );
}

export default FormNumberCreate