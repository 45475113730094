// Basic header component.

function FormField(props) {

    return (<div className="field-container" id={props.fieldID}>
        <div className="label-container">
            <label htmlFor={props.inputID}>{props.label}</label>
        </div>
        <div className="input-container">
            <div className="inner">
                <input
                    type="text"
                    name={props.inputName}
                    id={props.inputID}
                    autoComplete="off"
                    autoCorrect="off"
                    value={props.inputValue}
                    placeholder={props.inputPlaceholder}
                    onChange={props.onChange} />
            </div>
        </div>
    </div>)

}

export default FormField;