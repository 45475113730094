// Page for creating a new number.
import Popover from "../../components/Popover";
import FormNumberCreate from "../../components/Form/NumberCreate";

function PageNumberCreate() {

    // Render the component markup.
    // Component props.
    const PopoverProps = {
        cancelPath: "/",
        className: "number-create",
        popoverTitle: "Save number"
    };
    // Return markup.
    return (
        <Popover {...PopoverProps}>
            <div className="form-container">
                <div>
                    <FormNumberCreate></FormNumberCreate>
                </div>
            </div>
        </Popover >
    )
}

export default PageNumberCreate;