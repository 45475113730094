// Pop over component for showing lots of content in a block that can be canceled.
import * as React from 'react';
import { Link } from 'react-router-dom';


class Popover extends React.Component {
    render() {
        return (
            <div className={"popover " + this.props.className}>
                <div>
                    <div className="head">
                        { /* @todo Update cancel link to a Button component */}
                        <div className="left"><Link to={this.props.cancelPath} className="cancel"><span>{this.props.cancelLabel != null ? this.props.cancelLabel : "Cancel"}</span></Link></div>
                        <div className="middle"><span>{this.props.popoverTitle}</span></div>
                    </div>
                    <div className="content">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

export default Popover;
