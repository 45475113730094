// Man nav.
import * as React from 'react';

// Other components.
import NavigationMainPages from "./NavigationMainPages";
import NavigationUserSettings from "./NavigationUserSettings";

class NavigationMain extends React.Component {

    render() {
        return (
            <div id="main-nav">
                <div className="main-nav-pages">
                    <NavigationMainPages />
                </div>
                <div className="main-nav-settings">
                    <NavigationUserSettings />
                </div>
            </div>
        );
    }
}

export default NavigationMain;
