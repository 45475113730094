// Import React.
import * as React from 'react';
// Styles.
import './theme/styles/styles.scss';
// Import routing.
import { Routes, BrowserRouter, Route } from 'react-router-dom';
// Import layout.
import LayoutDefault from "./layouts/Default";
// Import pages.
import PageDefault from "./pages/Default";
import PageExplore from "./pages/Explore";
import PageNumberCreate from "./pages/number/Create";
import Page404 from "./pages/404";

class App extends React.Component {
  render() {
    return (<><BrowserRouter>
      <Routes>
        <Route element={<LayoutDefault />}></Route>
        <Route element={<LayoutDefault />}>
          <Route path="/" element={<PageDefault />}></Route>
          <Route path="/explore" element={<PageExplore />}></Route>
          <Route path="/number/create" element={<PageNumberCreate />}></Route>
          <Route path="*" element={<Page404 />}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
    </>)
  }
}

export default App;
