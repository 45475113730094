import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import ButtonCreateNumber from "../components/Button/CreateNumber";

const LayoutDefault = () => {
    return (
        <>
            <Header />
            <div id="page-content-container"><Outlet /></div>
            <div id="create-number-container">
                <div>
                    <ButtonCreateNumber />
                </div>
            </div>
        </>
    );
};

export default LayoutDefault;