// Default page.
import React from "react";
import Card from '../components/Card'

const PageDefault = () => {
    return (<>
        <Card backgroundColor="DarkMagenta" textColor="White" heading="What is Noomero?" text="Noomero is a fun and flexible app that lets you easily save and track any kind of number."></Card>
        <Card backgroundColor="MediumVioletRed" textColor="White" heading="How does it work?" text="Save your numbers with tags, location, and dates. Visualise your numbers in fun Noomero widgets."></Card>
    </>);
}

export default PageDefault;